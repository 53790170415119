export const formSwitchModalTop = '180px';

export const formModalWindowWidth = '510px';
export const formModalWindowPadding = '24px';
export const projectModalWindowWidth = '330px';
export const employeeModalWindowHeight = '110px';

export const addFormInputBoderColor = 'rgb(169 169 169 / 70%)';
export const genericPopUpWithButtonsDeleteDisplay = 'flex';
export const genericPopUpWithButtonsDeleteDrection = 'row';
export const genericPopUpWithButtonsDeleteMargin = '0 0 0 8px';

export const bookingClashFormPadding = '24px 10px';
export const minCellWidth = 189;
