import { FormControl, Select, MenuItem } from '@mui/material';
import StyledFormSelectController from 'components/sharedComponents/FormComponents/FormSelectDictionaries/StyledFormSelectController';
import {
  formSwitchModalTop,
  formModalWindowPadding,
  projectModalWindowWidth,
  employeeModalWindowHeight
} from 'constants/styleConstants';
import ModalWindow from 'modals/ModalWindow';
import { useState } from 'react';
import { Control, FieldValues, Controller } from 'react-hook-form';
import { ProjectDto } from 'types/project/dto/ProjectDto';
import ProjectFormDto from 'types/project/dto/ProjectFormDto';
import FormModalConfig from 'types/generalEnums/FormModalConfigType';
import { ProjectStatusName } from 'types/project/enums/ProjectStatusName';
import getStatusColor from 'helpers/getStatusColor';
import FormModalWindow from 'components/sharedComponents/FormComponents/FormModalWindow/FormModalWindow';

type FormStatusSelectorProps<T> = {
  control: Control<ProjectFormDto>;
  options: T[];
  title: keyof ProjectDto;
  label: string;
  error?: string;
  className?: string;
  disabled?: boolean;
  modalConfig?: FormModalConfig;
};

const FormStatusSelector = <T extends { id: number; displayName: string }>({
  title,
  control,
  options,
  label,
  error,
  className,
  disabled = false,
  modalConfig
}: FormStatusSelectorProps<T>) => {
  const [showModal, setShowModal] = useState(false);

  const handleChange = (newValue: number, field: FieldValues) => {
    const selected = options.find((item) => item.id === newValue);
    if (selected?.displayName === ProjectStatusName.Closed) {
      setShowModal(true);
    } else {
      field.onChange(newValue);
    }
  };

  const handleConfirm = (field: FieldValues) => {
    const selected = options.find((item) => item.displayName === ProjectStatusName.Closed);
    if (selected) {
      field.onChange(selected.id);
    }
    setShowModal(false);
  };

  return (
    <StyledFormSelectController>
      <Controller
        name={title}
        control={control}
        render={({ field }) => {
          const value = field.value as number;
          const isValidValue = options.some((item) => item.id === value);
          return (
            <FormControl>
              <p className="inter-caption_medium label">{label}</p>
              <Select
                {...field}
                value={isValidValue ? value : ''}
                className={className}
                disabled={disabled}
                onChange={(event) => {
                  const newValue = event.target.value as number;
                  handleChange(newValue, field);
                }}
              >
                {options.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    <div
                      style={{
                        backgroundColor: getStatusColor(item.displayName)
                      }}
                      className="status-icon"
                    />
                    {item.displayName}
                  </MenuItem>
                ))}
              </Select>
              {error && <p className="error inter-caption_medium">{error}</p>}
              {showModal && (
                <ModalWindow
                  headerTitle={modalConfig?.headerTitle}
                  onClose={() => setShowModal(false)}
                  className="modal-window-title"
                  style={{
                    width: projectModalWindowWidth,
                    height: employeeModalWindowHeight,
                    top: formSwitchModalTop,
                    padding: formModalWindowPadding
                  }}
                >
                  <FormModalWindow
                    field={field}
                    onSubmit={() => handleConfirm(field)}
                    description={modalConfig?.description}
                    btnText={modalConfig?.btnText}
                  />
                </ModalWindow>
              )}
            </FormControl>
          );
        }}
      />
    </StyledFormSelectController>
  );
};

export default FormStatusSelector;
